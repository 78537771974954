@font-face {
  font-family: 'Scto Grotesk A';
  src: url('./fonts/Scto-Grotesk-A-Light.woff') format('woff');
  font-weight: 300;
}

@font-face {
  font-family: 'Scto Grotesk A';
  src: url('./fonts/Scto-Grotesk-A-Regular.woff') format('woff');
  font-weight: 400;
}

@font-face {
  font-family: 'Scto Grotesk A';
  src: url('./fonts/Scto-Grotesk-A-Regular.woff') format('woff');
  font-weight: 500;
}

@font-face {
  font-family: 'Scto Grotesk A';
  src: url('./fonts/Scto-Grotesk-A-Bold.woff') format('woff');
  font-weight: 700;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input,
textarea {
  outline: none;
  font-family: 'Scto Grotesk A' !important;
}
